import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  connect() {
    this.start = this.start.bind(this);
    this.download = this.download.bind(this);
    this.currentUrl = new URL(window.location);

    const modalId = this.element.dataset.open;
    this.modal = document.getElementById(modalId);
    this.modalText = this.modal.querySelector(".pdf-generation-modal_text");
    this.pdfGenerationUrl = this.element.dataset.generatePdfUrl;
    this.pdfDownloadButton = this.modal.querySelector("button.pdf-download");

    this.element.addEventListener("click", this.start);
    this.pdfDownloadButton.addEventListener("click", this.download);
  }

  disconnect() {
    this.element.removeEventListener("click", this.start);
    this.pdfDownloadButton.removeEventListener("click", this.download);
  }

  start() {
    const pdfDownloadButton = this.modal.querySelector("button.pdf-download");
    const generatePdfUrl = this.element.dataset.generatePdfUrl;
    const { url, formData } = this.#requestData(generatePdfUrl);

    pdfDownloadButton.disabled = true;

    post(url, { body: formData }).then(async (response) => {
      let data = await response.response.json();

      const pdfName = data.pdf_name;
      const interval = setInterval(() => {
        get(data.bg_path)
          .then(async (response) => {
            response = await response.response.json();

            if (!response.background_request.complete) return;

            data = response.background_request.result;
            if (data.file == pdfName) {
              pdfDownloadButton.dataset.downloadUrl = data.url;
              this.modalText.textContent =
                "Your document is ready to download!";
              pdfDownloadButton.disabled = false;
              clearInterval(interval);
            }
          })
          .catch((err) => {
            clearInterval(interval);
            console.error(err);
          });
      }, 2000);
    });
  }

  download(event) {
    event.preventDefault();
    const url = this.pdfDownloadButton.dataset.downloadUrl;
    window.open(url, "_blank");
    const closeModal = this.modal.querySelector("[data-close]");
    closeModal.click();
  }

  #requestData(originalUrl) {
    let generatePdfUrl = new URL(
      this.currentUrl.protocol + this.currentUrl.host + originalUrl
    );

    // convert potentially long url to form data
    const pdfFormData = new FormData();
    generatePdfUrl.searchParams.entries().forEach(([key, value]) => {
      pdfFormData.append(key, value);
    });
    generatePdfUrl.searchParams.delete("player_ids[]");
    generatePdfUrl.searchParams.delete("player_ids");

    return { url: generatePdfUrl, formData: pdfFormData };
  }
}
